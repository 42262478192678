import React, {Component} from 'react';
import PriceInformation from './PriceInformation.js';
import Market from './Market.js';
//import Protection from './Protection.js';
import Partners from './Partners.js';

import '../../css/market.scss';
import '../../css/market-media.scss';
import {axiosCatch, axiosinstance} from "../../modules/axios";

class View extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            id: 0,
            rate: null,
            currency_send: [],
            currency_get: [],
            currency_from: "",
            currency_to: "",
            loading_error: false,
            loading_info: false,
            no_enabled_rates: false,
            form: {
                show: false,
                send: 1,
                get: 0,
                to_address: "",
                refund_address: "",
                email: ""
            },
            spot: null
        }
    }

    componentDidMount() {
        this.load();
        this.timer = setTimeout(() => {
            this.componentDidMount();
        }, Math.floor(Date.now() / 1000) * 1000 + 6000 - Date.now());
    };

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    load = () => {
        axiosinstance({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL + "/info",
            params: {}
        })
            .then((response) => {
                let ans = response.data;
                //ans = `{"success":true,"data":{"details":{"circulation":19168,"assets":19200,"last_price":54.49,"holders":266,"volume":{"v_24":16.5,"v_all_time":28515.002}},"fees":{"storage":9.95,"transfer":0},"market":{"spot":{"btc":0.00273457,"eth":0.03598976},"rate":[],"address_size":[{"name":"btc","size":34},{"name":"eth","size":42},{"name":"gold","size":42}],"pairs":{"currency_send":[],"currency_get":[]}}}}`
                if (ans.success === true) {

                    let currency_from = "";
                    let currency_to = "";
                    let send = 1;
                    let no_enabled_rates = true;

                    //rate
                    let map_rate = new Map();
                    for (let i = 0; i < ans.data.market.rate.length; i++) {
                        let rate = ans.data.market.rate[i];
                        map_rate.set(rate.pair, rate);

                        //if default not set
                        if (currency_from === "") {
                            currency_from = rate.currency_from;
                            currency_to = rate.currency_to;
                            if (send > rate.max_amount) {
                                send = rate.max_amount;
                            }
                        }
                        // end if


                        //if default finded
                        if (rate.default) {
                            currency_from = rate.currency_from;
                            currency_to = rate.currency_to;
                            //if max amount < 1
                            if (send > rate.max_amount) {
                                send = rate.max_amount;
                            }
                        }

                        // if enabled not found
                        if (rate.enabled && no_enabled_rates === true) {
                            no_enabled_rates = false
                        }
                    }

                    //currency_send
                    let currency_send = [];
                    let items_send = ans.data.market.pairs.currency_send;
                    for (let i = 0; i < items_send.length; i++) {
                        let item = {
                            name: items_send[i].name,
                            symbol: items_send.symbol,
                        };
                        currency_send.push(item);
                    }

                    //currency_get
                    let currency_get = [];
                    let items_get = ans.data.market.pairs.currency_get;
                    for (let i = 0; i < items_get.length; i++) {
                        let item = {
                            name: items_get[i].name,
                            symbol: items_get[i].symbol,
                        };
                        currency_get.push(item);
                    }

                    // if (items_get.length === 0 || items_send.length === 0) {
                    //     no_enabled_rates = true
                    // }
                    // no_enabled_rates = true

                    //console.log(mapRate);
                    let form = this.state.form;
                    form["send"] = send;

                    //console.log('first 1 ' + this.state.first_load.toString());
                    this.setState({
                        id: new Date(),
                        rate: map_rate,
                        currency_send: currency_send,
                        currency_get: currency_get,
                        currency_from: currency_from,
                        currency_to: currency_to,
                        loading_error: false,
                        loading_info: true,
                        form: form,
                        no_enabled_rates: no_enabled_rates,
                        spot: ans.data.market.spot

                    });

                }
            }).catch((args) => {
            console.log('error');
            this.setState({
                id: new Date(),
                loading_error: true,
                error: "internal error",
                no_enabled_rates: true,
            });

            axiosCatch(args, this.props);
        });

    };

    render() {

        return (


            <div>
                <PriceInformation {...this.state} id={this.props.match.params.id}/>
                <Market {...this.state}/>
                {/*<Protection/>*/}
                <Partners/>
            </div>
        )
    }
}

export default View;
