import React, {Component} from 'react';
import {translate} from 'react-i18next';

class View extends Component {

    render() {

        let partners = [
            {
               name: "Cryptex",
               texturl: "https://cryptex.net",
               url: "https://cryptex.net/trade/GOLDUSD"
            }
        ];

        // {
        //     name: "BitForex",
        //       texturl: "https://www.bitforex.com",
        //   url: "https://www.bitforex.com/en/spot/gold_btc"
        // },
        // {
        //     name: "Uniswap V2",
        //       texturl: "https://app.uniswap.org",
        //   url: "https://app.uniswap.org/#/swap?exactField=input&inputCurrency=0x851E13B3cdCaEc6FB3ae446dAd82E112ABaA827c&outputCurrency=0xdac17f958d2ee523a2206206994597c13d831ec7\n"
        // }
        // {
        //     name: "LiveCoin",
        //     texturl: "https://livecoin.net",
        //     url: "https://livecoin.net/en/trading/GOLD_BTC"
        // },
        // ,
        // {
        //     name: "CoinsBit",
        //     texturl: "https://coinsbit.io",
        //     url: "https://coinsbit.io/ru/trade/GOLD_BTC"
        // }
        // {
        //     name: "Catex",
        //         texturl: "https://www.catex.io",
        //     url: "https://www.catex.io/trading/GOLD/BTC"
        // },

        const {
            t,
        } = this.props;

        return (
            <div className={'market-partners-bar bar-padding'}>
                <div className={'max-width width'}>

                    <div className={'left width'}>
                        <h2 dangerouslySetInnerHTML={{__html: t('market.partners.title')}}/>
                        <div className={'text'}>{t('market.partners.text')}</div>
                        <br/>

                        {partners.map(item =>
                        <div className={'flex-row width partners-div'} key={item.url}>
                            <div className={'logo'} style={{width: 200}}>
                                <a target={'_new'} href={item.url}><img border={0} src={'/img/partners/' + item.name.toLowerCase().split(" ")[0] +'.png'} alt={''} width={'240px'}/></a>
                            </div>
                            <div className={'text'} style={{width: '90%', marginLeft: 20}}>
                                {t('market.partners.partners.'+ item.name.toLowerCase().split(" ")[0])}<br/><br/>
                                <div className={'url'}>
                                    <a target={'_new'} href={item.url}>{item.texturl}</a>
                                </div>
                            </div>
                        </div>
                        )}

                    </div>
                </div>
            </div>
        )
    }
}

export default translate('locale')(View);
